import { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css"; // Uncomment this if you want default calendar styling

const EventCalendar = () => {
  const [value, onChange] = useState(new Date());

  return (
    <div className="w-full max-w-4xl mx-auto p-4">
      <Calendar onChange={onChange} value={value} className="w-full border rounded-lg shadow-lg" />
    </div>
  );
};

export default EventCalendar;
