import React, { useState, useEffect } from "react";

const Announcements = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [viewAll, setViewAll] = useState(false); // State to toggle "View All"

  useEffect(() => {
    // Simulate async data fetching
    const fetchData = async () => {
      const announcements = [
        {
          id: 1,
          title: "Welcome Back to School!",
          description: "We hope you had a great vacation.",
          date: "2024-12-10",
          bgColor: "bg-lamaSkyLight",
        },
        {
          id: 2,
          title: "Exam Schedule Released",
          description: "Check the portal for details.",
          date: "2024-12-09",
          bgColor: "bg-lamaPurpleLight",
        },
        {
          id: 3,
          title: "Parent-Teacher Meeting",
          description: "Join us on December 15th.",
          date: "2024-12-08",
          bgColor: "bg-lamaYellowLight",
        },
        {
          id: 4,
          title: "New Library Books",
          description: "Explore the latest arrivals.",
          date: "2024-12-07",
          bgColor: "bg-lamaSkyLight",
        },
        {
          id: 5,
          title: "Winter Break Announcement",
          description: "School closes on December 20th.",
          date: "2024-12-06",
          bgColor: "bg-lamaPurpleLight",
        },
      ];
      setData(announcements);
      setLoading(false);
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const displayedData = viewAll ? data : data.slice(0, 3); // Show all or top 3 based on viewAll

  return (
    <div className="bg-white p-4 rounded-md">
      <div className="flex items-center justify-between">
        <h1 className="text-xl font-semibold">Announcements</h1>
        <span
          className="text-xs text-blue-500 cursor-pointer"
          onClick={() => setViewAll((prev) => !prev)} // Toggle viewAll state
        >
          {viewAll ? "Show Less" : "View All"}
        </span>
      </div>
      <div className="flex flex-col gap-4 mt-4">
        {displayedData.map((announcement) => (
          <div key={announcement.id} className={`${announcement.bgColor} rounded-md p-4`}>
            <div className="flex items-center justify-between">
              <h2 className="font-medium text-[16px]">{announcement.title}</h2>
              <span className="text-xs text-gray-400 bg-white rounded-md px-1 py-1">
                {new Intl.DateTimeFormat("en-GB").format(new Date(announcement.date))}
              </span>
            </div>
            <p className="text-sm text-gray-400 mt-1">{announcement.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Announcements;
