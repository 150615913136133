import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import PropTypes from "prop-types";

// Register required Chart.js components
ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const StudentPerformanceChart = ({ performance }) => {
  // Extract subject names for labeling
  const subjects = performance.map((subjectData) => Object.keys(subjectData)[0]);

  // Create datasets dynamically based on performance data
  const datasets = performance.map((subjectData, index) => {
    const [subject, students] = Object.entries(subjectData)[0];

    return {
      label: subject,
      data: students.map((student) => student.totalScore),
      backgroundColor: `rgba(${100 + index * 40}, ${150 + index * 30}, ${200 - index * 20}, 0.6)`,
      borderColor: `rgba(${100 + index * 40}, ${150 + index * 30}, ${200 - index * 20}, 1)`,
      borderWidth: 1,
    };
  });

  // Create labels based on the first subject's student data
  const labels =
    performance.length > 0 ? Object.values(performance[0])[0].map((student) => student.name) : [];

  // Define chart data
  const chartData = {
    labels,
    datasets,
  };

  // Define chart configuration options
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false, // Disable automatic aspect ratio to control height
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Student Performance by Subject",
        font: {
          size: 18,
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Students",
          font: {
            size: 14,
          },
        },
        ticks: {
          autoSkip: false, // Avoid skipping ticks when there are many students
          maxRotation: 90, // Rotate labels for better visibility
          minRotation: 45,
        },
      },
      y: {
        title: {
          display: true,
          text: "totalScores",
          font: {
            size: 14,
          },
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="p-6 bg-gray-50 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold text-center mb-6">Student Performance</h2>
      {performance.length > 0 ? (
        <div style={{ height: "200px" /* Increase height of chart container */ }}>
          <Bar data={chartData} options={chartOptions} />
        </div>
      ) : (
        <p className="text-center text-gray-500">No performance data available.</p>
      )}
    </div>
  );
};

// Define prop types for performance data validation
StudentPerformanceChart.propTypes = {
  performance: PropTypes.arrayOf(
    PropTypes.shape({
      [PropTypes.string]: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          totalScore: PropTypes.number.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
};

export default StudentPerformanceChart;
