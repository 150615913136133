import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import AttendanceComp from "./attendancecomponent";
import { useEffect, useState } from "react";
import { FilterAlt } from "@mui/icons-material";
import { getStudentsByClass } from "utils/requests/students/studentsget";
import { profile } from "context/profile";
import PropTypes from "prop-types";
import Snackbar from "awesome-snackbar";
import { postattendance } from "utils/requests/attendance/attendancepost";
import { getStudentForAttendance } from "utils/requests/students/studentsget";

function AttendanceList(props) {
  const { handler } = props;
  const [selectedItems, setSelectedItems] = useState([]);
  const [fetched, setFetched] = useState([]);
  const [click, setClick] = useState(false);
  const [isSpecificClass, setIsSpecificClass] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await getStudentForAttendance();
        console.log("response from server of attendance", JSON.stringify(response, null, 2));
        // Ensure the response and the data field are defined before setting fetched data
        if (response.success) {
          setFetched(response.data);
        } else {
          console.error("Unexpected response structure:");
        }
      } catch (error) {
        console.error("Error fetching students:");
      }
    };
    fetch();
  }, []);

  // Handle student selection
  const handleClick = (index, student) => {
    if (selectedItems.includes(index)) {
      setSelectedItems(selectedItems.filter((item) => item !== index));
      handler(student.student_id, false);
    } else {
      handler(student.student_id, true);
      setSelectedItems([...selectedItems, index]);
    }
  };

  const clickhandler = () => {
    setClick(!click);
    setIsSpecificClass(false);
  };

  const checkIfSelected = (index) => selectedItems.includes(index);

  const handleSubmit = async () => {
    const currentDate = new Date();
    const attendanceRecords = fetched.map((student) => {
      // For each student, create attendance record
      return {
        date: currentDate.toISOString().split("T")[0], // Format: YYYY-MM-DD
        time: currentDate.toTimeString().split(" ")[0],
        student_id: student.student_id,
        staff_id: profile.staff_id,
        type: selectedItems.includes(fetched.indexOf(student)) ? "absent" : "present", // Determine type based on selection
      };
    });

    const response = await postattendance(attendanceRecords);
    if (response.success) {
      new Snackbar(`${response.message}`, {
        theme: "light",
        position: "top-center",
        style: {
          container: [
            ["background-color", "green"],
            ["color", "white"],
          ],
        },
      });
      setSelectedItems([]);
      setFetched([]);
      setClick(false);
    } else {
      new Snackbar(response.message, {
        theme: "light",
        position: "top-center",
        style: {
          container: [
            ["background-color", "red"],
            ["color", "white"],
          ],
        },
      });
    }
  };

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="h6" fontWeight="medium">
          Choose Recipients
        </MDTypography>
        <MDButton variant="outlined" color="info" size="small" onClick={clickhandler}>
          View All &nbsp;
          <FilterAlt style={{ fontWeight: "bold" }} />
        </MDButton>
      </MDBox>
      <MDBox p={2}>
        {!click && !isSpecificClass && (
          <MDBox component="ul" display="flex" flexDirection="column" p={0} mt={0}>
            {fetched.map((student, index) => {
              return (
                <MDBox
                  key={index}
                  onClick={() => handleClick(index, student)}
                  sx={{
                    cursor: "pointer",
                    backgroundColor: selectedItems.includes(index) ? "#f0f2f5" : "transparent",
                    marginTop: 1,
                    borderRadius: "8px",
                    padding: "8px",
                  }}
                >
                  <AttendanceComp
                    name={student.first_name}
                    lastName={student.middele ? student.middele : student.last_name}
                    grade={student?.class?.grade_level}
                    section={student?.class?.section}
                    selected={checkIfSelected(index)}
                  />
                </MDBox>
              );
            })}
          </MDBox>
        )}
        <MDButton onClick={handleSubmit} variant="gradient" color="info" fullWidth>
          Submit
        </MDButton>
      </MDBox>
    </Card>
  );
}

AttendanceList.propTypes = {
  handler: PropTypes.func.isRequired,
};

export default AttendanceList;
