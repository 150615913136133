import { adjustScheduleToCurrentWeek } from "./adjustScheduleToCurrentWeek";
import PropsTypes from "prop-types";
import BigCalendar from "./BigCalendar";
// Mock data for lessons
const mockData = [
  {
    title: "Math Class",
    startTime: new Date(2024, 11, 1, 9, 0), // 1st Dec, 2024, 9:00 AM
    endTime: new Date(2024, 11, 1, 10, 0), // 1st Dec, 2024, 10:00 AM
  },
  {
    title: "Science Class",
    startTime: new Date(2024, 11, 1, 10, 30), // 1st Dec, 2024, 10:30 AM
    endTime: new Date(2024, 11, 1, 11, 30), // 1st Dec, 2024, 11:30 AM
  },
  {
    title: "English Class",
    startTime: new Date(2024, 11, 1, 13, 0), // 1st Dec, 2024, 1:00 PM
    endTime: new Date(2024, 11, 1, 14, 0), // 1st Dec, 2024, 2:00 PM
  },
];

const BigCalendarContainer = ({ type, id }) => {
  // Use the mock data as the "fetched" data
  const data = mockData.map((lesson) => ({
    title: lesson.title,
    start: lesson.startTime,
    end: lesson.endTime,
  }));

  // Adjust the schedule to the current week
  const schedule = adjustScheduleToCurrentWeek(data);

  return (
    <div className="">
      <BigCalendar data={schedule} />
    </div>
  );
};

BigCalendarContainer.propTypes = {
  type: PropsTypes.string,
  id: PropsTypes.string,
};

export default BigCalendarContainer;
