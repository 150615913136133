import { Card, CardContent, MenuItem, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { generateQuestion } from "utils/requests/generateQuetion/generateQuestion";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { snackbar } from "components/awesome_snackbar/snackbar";
import { LoadinSpiner } from "components/LoadingSpinner";
import { getTeachersClassesByTeacherId } from "utils/requests/teachers/assignClass";
import { profile } from "context/profile";

const examType = [
  { exam_id: 1, exam_name: "hard" },
  { exam_id: 2, exam_name: "moderate" },
  { exam_id: 3, exam_name: "easy" },
];
const standard = [
  {
    id: 1,
    standardContext: "Based on Ethiopia",
  },
  {
    id: 2,
    standardContext: "Based on Global",
  },
];

const GenerateExamForm = ({ onQuestionsGenerated }) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [assignClass, setAssignClass] = useState([]);
  const [examList, setExamList] = useState({
    examTopic: "",
    examType: "",
    standard: "",
    trueFalseQuestions: "",
    shortAnswerQuestions: "",
    blackSpaceQuestions: "",
    choiceQuestions: "",
    gradeLevel: "",
  });
  const { role } = profile;

  useEffect(() => {
    const fetchAssignedClassData = async () => {
      if (role === "teacher") {
        const res = await getTeachersClassesByTeacherId();
        if (res.success) {
          const assignedClass = res.data.map((item) => item.class);
          setAssignClass(assignedClass ? assignedClass : []);
        } else {
          console.log(res.success);
        }
      }
    };
    fetchAssignedClassData();
  }, []);

  const handleInputChange = (field, value) => {
    setExamList((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = async () => {
    try {
      if (examList.gradeLevel === "") {
        snackbar("error", "Please select a grade level");
        return;
      }
      if (examList.standard === "") {
        snackbar("error", "Please select  question context");
        return;
      }
      if (examList.examTopic === "") {
        snackbar("error", "Please enter an exam topic");
        return;
      }
      const hasValidQuestions =
        examList.trueFalseQuestions ||
        examList.shortAnswerQuestions ||
        examList.blackSpaceQuestions ||
        examList.choiceQuestions;

      if (!hasValidQuestions) {
        snackbar(
          "error",
          "Please provide at least one value for True/False, Choice, Black Space, or Short Answer questions."
        );
        return;
      }
      const data = {
        examType: examList.examType,
        examTopic: examList.examTopic,
        standard: examList.standard,
        trueFalseQuestions: examList.trueFalseQuestions,
        shortAnswerQuestions: examList.shortAnswerQuestions,
        blackSpaceQuestions: examList.blackSpaceQuestions,
        choiceQuestions: examList.choiceQuestions,
        gradeLevel: examList.gradeLevel,
      };
      setIsGenerating(true);
      const res = await generateQuestion(data);

      if (res.success && res.data) {
        setIsGenerating(false);
        snackbar("success", res.message);
        onQuestionsGenerated(res); // Ensure data is passed
        setExamList({
          examTopic: "",
          examType: "",
          standard: "",
          trueFalseQuestions: "",
          shortAnswerQuestions: "",
          blackSpaceQuestions: "",
          choiceQuestions: "",
          gradeLevel: "",
        });
      } else {
        setIsGenerating(false);
        console.error("Invalid response from server:", res);
        snackbar("error", "Failed to generate questions.");
      }
    } catch (error) {
      setIsGenerating(false);
      console.error("Error submitting form:", error);
      snackbar("error", "An error occurred while generating questions.");
    }
  };

  return (
    <Card sx={{ height: "auto", width: "100%" }}>
      <CardContent>
        <MDBox
          pt={2}
          px={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <MDTypography variant="h6" fontWeight="medium">
            Generate question
          </MDTypography>
        </MDBox>
        <MDBox>
          <MDBox display="flex" px={2} mt={2} gap={2}>
            <TextField
              fullWidth
              select
              label="Grade Level"
              value={examList.gradeLevel}
              onChange={(e) => handleInputChange("gradeLevel", e.target.value)}
              sx={{ "& .MuiInputBase-root": { height: "44px" }, width: { xs: "100%", sm: "45%" } }}
            >
              {Array.from(new Set(assignClass.map((cls) => cls.grade_level))).map((grade) => (
                <MenuItem key={grade} value={grade}>
                  {grade}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              fullWidth
              select
              label="Question Context"
              value={examList.standard}
              onChange={(e) => handleInputChange("standard", e.target.value)}
              sx={{ "& .MuiInputBase-root": { height: "44px" }, width: { xs: "100%", sm: "45%" } }}
            >
              {standard.map((type) => (
                <MenuItem key={type.id} value={type.standardContext}>
                  {type.standardContext}
                </MenuItem>
              ))}
            </TextField>
          </MDBox>
          <MDBox
            display="flex"
            gap={2}
            px={2}
            mt={2}
            sx={{ flexDirection: { xs: "column", sm: "row" } }}
          >
            <MDInput
              label="Question Topic"
              value={examList.examTopic}
              onChange={(e) => handleInputChange("examTopic", e.target.value)}
              sx={{ width: { xs: "100%", sm: "45%" } }}
              required
            />
            <TextField
              fullWidth
              select
              label="Question Type"
              value={examList.examType}
              onChange={(e) => handleInputChange("examType", e.target.value)}
              sx={{ "& .MuiInputBase-root": { height: "44px" }, width: { xs: "100%", sm: "45%" } }}
            >
              {examType.map((type) => (
                <MenuItem key={type.exam_id} value={type.exam_name}>
                  {type.exam_name}
                </MenuItem>
              ))}
            </TextField>
          </MDBox>

          <MDBox
            display="flex"
            gap={2}
            px={2}
            mt={2}
            sx={{ flexDirection: { xs: "column", sm: "row" } }}
          >
            <MDInput
              label="True / False Questions"
              value={examList.trueFalseQuestions}
              onChange={(e) => handleInputChange("trueFalseQuestions", e.target.value)}
              sx={{ width: { xs: "100%", sm: "45%" } }}
              type="number"
              required
            />
            <MDInput
              label="Black Space Questions"
              value={examList.blackSpaceQuestions}
              onChange={(e) => handleInputChange("blackSpaceQuestions", e.target.value)}
              sx={{ width: { xs: "100%", sm: "45%" } }}
              required
            />
          </MDBox>

          <MDBox
            display="flex"
            gap={2}
            px={2}
            mt={2}
            sx={{ flexDirection: { xs: "column", sm: "row" } }}
          >
            <MDInput
              label="Short Answer Questions"
              value={examList.shortAnswerQuestions}
              onChange={(e) => handleInputChange("shortAnswerQuestions", e.target.value)}
              sx={{ width: { xs: "100%", sm: "45%" } }}
              type="number"
              required
            />
            <MDInput
              label="Choice Questions"
              value={examList.choiceQuestions}
              onChange={(e) => handleInputChange("choiceQuestions", e.target.value)}
              sx={{ width: { xs: "100%", sm: "45%" } }}
              required
            />
          </MDBox>
          {!isGenerating ? (
            <MDButton
              color="info"
              variant="contained"
              sx={{ mt: 3, width: "20%", ml: "15%" }}
              onClick={handleSubmit}
            >
              Generate Question
            </MDButton>
          ) : (
            <LoadinSpiner />
          )}
        </MDBox>
      </CardContent>
    </Card>
  );
};

GenerateExamForm.propTypes = {
  onQuestionsGenerated: PropTypes.func.isRequired,
};

export default GenerateExamForm;
