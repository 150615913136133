import React, { useEffect, useRef } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import PropTypes from "prop-types";

// Register Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

const centerIconsPlugin = {
  id: "centerIcons",
  afterDraw: (chart) => {
    const { ctx, width, height } = chart;
    const total = chart.data.datasets[0].data.reduce((acc, val) => acc + val, 0);
    const centerX = width / 2;
    const centerY = height / 2;

    const femaleIcon = new Image();
    femaleIcon.src = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
        imageRendering="optimizeQuality"
        fillRule="evenodd"
        clipRule="evenodd"
        viewBox="0 0 488 511.99"
      >
        <path
          fill="#5AC1F2"
          d="M96.49 0c23.79 0 43.08 19.29 43.08 43.08s-19.29 43.08-43.08 43.08c-23.8 0-43.08-19.29-43.08-43.08S72.69 0 96.49 0zm52.65 162.22v325.95c0 13.11-10.72 23.82-23.82 23.82s-23.82-10.71-23.82-23.82V296.51h-9.39v191.66c0 13.1-10.72 23.82-23.82 23.82s-23.82-10.71-23.82-23.82V162.22h-8.33v119.76c0 9.94-8.13 18.07-18.06 18.07h-.01C8.13 300.05 0 291.92 0 281.98v-128.9c-.05-20.95 7.05-35.64 19.89-45.32 22.08-16.64 131.08-16.65 153.17-.02 12.85 9.67 19.96 24.38 19.92 45.34v128.9c0 9.94-8.13 18.07-18.07 18.07h-.01c-9.93 0-18.06-8.13-18.06-18.07V162.22h-7.7z"
        />
        <path
          fill="#F576AB"
          d="M354 0c23.79 0 43.08 19.29 43.08 43.08S377.79 86.16 354 86.16s-43.08-19.29-43.08-43.08S330.21 0 354 0zm95.26 148.08c.31.72.58 1.48.8 2.26l37.25 132.51c2.76 9.84-2.99 20.07-12.83 22.83-9.84 2.76-20.07-2.99-22.83-12.83l-36.76-130.77-.55.14h-7.69v8.3l49.02 194.98h-49.02v122.67c0 13.11-10.72 23.82-23.82 23.82S359 501.28 359 488.17V365.5h-9.38v122.67c0 13.1-10.72 23.82-23.82 23.82s-23.82-10.71-23.82-23.82V365.5h-51.96l51.96-200.16v-3.12h-8.33l-.68-.17-36.77 130.8c-2.76 9.84-12.99 15.59-22.83 12.83-9.84-2.76-15.59-12.99-12.83-22.83l36.45-129.68-.36-.09c5.58-20.86 10.24-35.64 23.08-45.32 22.08-16.64 128.77-16.65 150.86-.02 11.88 8.94 13.97 20.69 18.69 40.34z"
        />
      </svg>
    );

    femaleIcon.onload = () => {
      ctx.save();
      const iconSize = 30;
      const femaleIconX = centerX - 20;
      const femaleIconY = centerY - iconSize / 2;
      ctx.drawImage(femaleIcon, femaleIconX, femaleIconY, iconSize, iconSize);
      ctx.restore();
    };
  },
};

ChartJS.register(centerIconsPlugin);

const GenderDistributionChart = ({ maleCount, femaleCount, otherCount }) => {
  const data = {
    labels: ["Male", "Female", "Other"],
    datasets: [
      {
        data: [maleCount, femaleCount, otherCount],
        backgroundColor: [
          "rgba(54, 162, 235, 0.8)", // blue for male
          "rgba(255, 99, 132, 0.8)", // pink for female
          "rgba(75, 192, 192, 0.8)", // green for other
        ],
        borderColor: ["rgba(54, 162, 235, 1)", "rgba(255, 99, 132, 1)", "rgba(75, 192, 192, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          padding: 20,
          font: {
            size: 12,
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const total = maleCount + femaleCount + otherCount;
            const value = context.raw;
            const percentage = ((value / total) * 100).toFixed(1);
            return `${context.label}: ${value} (${percentage}%)`;
          },
        },
      },
    },
    cutout: "60%",
  };

  return (
    <div className="h-[300px] w-full">
      <Doughnut data={data} options={options} />
    </div>
  );
};

GenderDistributionChart.propTypes = {
  maleCount: PropTypes.number.isRequired,
  femaleCount: PropTypes.number.isRequired,
  otherCount: PropTypes.number.isRequired,
};

export default GenderDistributionChart;
