import React, { useState, useRef, useEffect } from "react";
import { Card, CardContent, Grid, TextField, CircularProgress, MenuItem } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { snackbar } from "components/awesome_snackbar/snackbar";
import { createEvent } from "utils/requests/event/createEvent";
import PropTypes from "prop-types";
import MDButton from "components/MDButton";
import { getClassBySchool } from "utils/requests/class/getClass";
import Lottie from "lottie-react";
import SelectFile from "assets/lottie/Animation - 1709585629758.json";
import CheckingFile from "assets/lottie/Animation - 1709585724092.json";
import { LoadinSpiner } from "components/LoadingSpinner";

function AddEventForm({ onSuccess }) {
  const [formData, setFormData] = useState({
    title: "",
    date: "",
    time: "",
    location: "",
    description: "",
    organizer: "",
    banner: null,
    audience: "",
    grade: "",
  });
  const [classData, setClassData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const titleInputRef = useRef(null);

  useEffect(() => {
    const fetch = async () => {
      const res = await getClassBySchool();
      if (res.success) {
        setClassData(res.data);
      }
    };
    fetch();
  }, []);

  useEffect(() => {
    if (titleInputRef.current) {
      titleInputRef.current.focus();
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const validTypes = ["image/jpeg", "image/png", "image/jpg"];

      if (!validTypes.includes(file.type)) {
        setErrors({ ...errors, banner: "Only image files (JPEG, PNG) are allowed." });
        return;
      }
      setErrors({ ...errors, banner: "" }); // Clear any previous banner errors
      const reader = new FileReader();

      reader.onloadend = () => {
        const imageData = reader.result; // This is the image data as a string
        setFormData((prevFormData) => ({
          ...prevFormData,
          banner: imageData, // Set the banner field correctly
        }));
      };

      reader.readAsDataURL(file);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.title.trim()) newErrors.title = "Event title is required.";
    if (!formData.description.trim()) newErrors.description = "Event description is required.";
    if (!formData.date) newErrors.date = "Event date is required.";
    if (!formData.time) newErrors.time = "Event time is required.";
    if (!formData.location.trim()) newErrors.location = "Event location is required.";
    if (!formData.organizer.trim()) newErrors.organizer = "Event organizer is required.";
    if (!formData.banner) newErrors.banner = "Event banner is required.";
    if (!formData.audience.trim()) newErrors.audience = "Please specify who the event is for.";
    if (formData.audience === "students" && !formData.grade) {
      newErrors.grade = "Please select a grade or choose 'All' for students.";
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setLoading(true);
    try {
      // Create a new FormData object
      const formDataToSend = new FormData();
      // Append each field from the form data
      console.log("formDataToSend", formDataToSend);
      Object.entries(formData).forEach(([key, value]) => {
        if (key !== "banner") {
          formDataToSend.append(key, value);
        }
      });

      // Append the banner file
      if (formData.banner) {
        formDataToSend.append("banner", formData.banner);
      }
      // Send the FormData to the backend
      const response = await createEvent(formDataToSend); // Ensure `createEvent` can handle FormData
      if (response.success) {
        snackbar("success", response.message);
        onSuccess();
        setFormData({
          title: "",
          date: "",
          time: "",
          location: "",
          description: "",
          organizer: "",
          banner: null,
          audience: "",
          grade: "",
        });
      } else {
        snackbar("error", response.message || "Failed to create event.");
      }
    } catch (error) {
      console.log("error", error);
      snackbar("error", "Something went wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <MDBox>
      <Card>
        <CardContent>
          <MDTypography variant="h6" gutterBottom>
            Add Event
          </MDTypography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Event Title"
                  name="title"
                  value={formData.title}
                  onChange={handleInputChange}
                  error={!!errors.title}
                  helperText={errors.title}
                  inputRef={titleInputRef}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Event Description"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  error={!!errors.description}
                  helperText={errors.description}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="date"
                  label="Event Date"
                  name="date"
                  value={formData.date}
                  onChange={handleInputChange}
                  error={!!errors.date}
                  helperText={errors.date}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="time"
                  label="Event Time"
                  name="time"
                  value={formData.time}
                  onChange={handleInputChange}
                  error={!!errors.time}
                  helperText={errors.time}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              {/* Event Location */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Event Location"
                  name="location"
                  value={formData.location}
                  onChange={handleInputChange}
                  error={!!errors.location}
                  helperText={errors.location}
                />
              </Grid>

              {/* Event Organizer */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Event Organizer"
                  name="organizer"
                  value={formData.organizer}
                  onChange={handleInputChange}
                  error={!!errors.organizer}
                  helperText={errors.organizer}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  select
                  label="Event audience"
                  name="audience"
                  value={formData.audience}
                  onChange={handleInputChange}
                  error={!!errors.audience}
                  helperText={errors.audience}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "44px",
                    },
                  }}
                >
                  <MenuItem value="teachers">Teachers</MenuItem>
                  <MenuItem value="students">Students</MenuItem>
                  <MenuItem value="all">Everyone</MenuItem>
                </TextField>
              </Grid>

              {formData.audience === "students" && (
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    select
                    label="Select Grade"
                    name="grade"
                    value={formData.grade}
                    onChange={handleInputChange}
                    error={!!errors.grade}
                    helperText={errors.grade}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: "44px",
                      },
                    }}
                  >
                    <MenuItem value="all">All Students</MenuItem>
                    {Array.from(new Set(classData.map((cls) => cls.grade_level))).map((grade) => (
                      <MenuItem key={grade} value={grade}>
                        {grade}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              )}

              {/* File Upload */}
              <Grid item xs={12}>
                <MDBox
                  sx={{
                    textAlign: "center",
                  }}
                >
                  <input
                    type="file"
                    accept="image/jpeg,image/png"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    id="event-banner-upload"
                  />
                  <label htmlFor="event-banner-upload" className="cursor-pointer">
                    <Lottie
                      animationData={formData.banner ? CheckingFile : SelectFile}
                      loop={false}
                      style={{ height: 100 }}
                    />
                    <MDTypography variant="caption">
                      {formData.banner ? "File Ready to Upload" : "Click or Drag to Upload Banner"}
                    </MDTypography>
                  </label>
                  {errors.banner && (
                    <MDTypography color="error" variant="caption">
                      {errors.banner}
                    </MDTypography>
                  )}
                </MDBox>
              </Grid>

              {/* Submit Button */}
              <Grid item xs={12} display="flex" justifyContent="flex-end">
                <MDButton
                  variant="contained"
                  color="info"
                  style={{
                    color: "white",
                  }}
                  type="submit"
                  disabled={loading}
                >
                  Create Event
                </MDButton>
              </Grid>
            </Grid>
          </form>
        </CardContent>
        {loading && <LoadinSpiner />}
      </Card>
    </MDBox>
  );
}

AddEventForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  grades: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default AddEventForm;
