import { Card, CardContent } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const CardItem = ({ label, value, Icon }) => {
  return (
    <Card className="h-full">
      <CardContent className="h-full flex flex-col  py-6 px-4">
        <Icon fontSize="72px" className="text-4xl text-blue-500 mb-2" />
        <p className="text-2xl font-bold mb-1">{value}</p>
        <p className="text-sm font-medium text-gray-500">{label}</p>
      </CardContent>
    </Card>
  );
};

CardItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  Icon: PropTypes.elementType, // Updated here
};

export default CardItem;
